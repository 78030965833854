import React from "react"
import styled from 'styled-components';
import Img from 'gatsby-image';

const Image = styled(Img)`
    display: none !important;
`;

const SeoImage = ({ fluid }) => (
    <Image fluid={fluid} alt='Baner promocyjny' />
);

export default SeoImage;