import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ThemeProvider } from 'styled-components';

import { GlobalStyle } from '../utils/globalStye'
import { theme } from '../utils/theme';
import SeoImage from '../components/seo/SeoImage'
import NavbarCentered from '../components/navbar/NavbarCentered';
import Footer from '../components/footer/footer';
import Cookie from '../components/cookie/Cookie'

const Layout2 = (props) => {

  const data = useStaticQuery(graphql`
  query {
    logoWhite: file(relativePath: { eq: "logo/logo_white.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    logoGold: file(relativePath: { eq: "logo/logo_gold.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    logoIks: file(relativePath: { eq: "logo/iks/logo.png" }) {
      childImageSharp {
        fixed(quality: 100, height: 20) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    seo_image: file(relativePath: { eq: "SEO_image.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    datoCmsKontakt {
      footerSubHeading
      footerHeading
      daneTeleadresowe {
        ulica
        nrBudnku
        miasto
        kod
        nrTel
        email
        woj
        kraj
      }
    }
  }
  `);

  const logoWhite = data.logoWhite.childImageSharp.fluid
  const logoGold = data.logoGold.childImageSharp.fluid
  const logoIks = data.logoIks.childImageSharp.fixed
  const seo_image = data.seo_image.childImageSharp.fluid
  const kontakt = data.datoCmsKontakt.daneTeleadresowe[0]
  const footer = data.datoCmsKontakt

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <div>
        <SeoImage fluid={seo_image} />
        <NavbarCentered
          logo={logoGold}
          logo_mobile={logoWhite}
          tel={kontakt.nrTel}
          email={kontakt.email} />

        <div>
          {props.children}
        </div>

        <Cookie />
        <Footer
          logo={logoWhite}
          tel={kontakt.nrTel}
          email={kontakt.email}
          ulica={kontakt.ulica}
          nrBud={kontakt.nrBudnku}
          miasto={kontakt.miasto}
          kod={kontakt.kod}
          woj={kontakt.woj}
          kraj={kontakt.kraj}
          heading={footer.footerHeading}
          subHeading={footer.footerSubHeading}
          iks={logoIks}
        />
      </div>
    </ThemeProvider>
  )
}

export default Layout2