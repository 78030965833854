import { createGlobalStyle } from 'styled-components';
import media from "styled-media-query";

export const GlobalStyle = createGlobalStyle`
*,
*::before,
*::after {
    box-sizing: border-box;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    background-color: transparent;
    font-size: ${({ theme }) => theme.font.size};
    font-family: ${({ theme }) => theme.font.main};
    color: ${({ theme }) => theme.color.font};
    font-weight: ${({ theme }) => theme.font.weight};
    line-height: 1.5;
    text-align: left;
    margin: 0;
    padding: 0;
    
}

p {
    font-weight: ${({ theme }) => theme.font.weight};
    margin: 0;
    padding: 0;
}

p, a, li {
    color: ${({ theme }) => theme.color.font};
    font-family: ${({ theme }) => theme.font.main};
    
    ${media.lessThan("small")`
    font-size: 16px;
    line-height: 1.5;
    `}

    ${media.between("small", "medium")`
    font-size: 17px;
    line-height: 1.5;
    `}

    ${media.between("medium", "large")`
    font-size: 18px;
    line-height: 1.6;
    `}

    ${media.between("large", "huge")`
    font-size: 19px;
    line-height: 1.7;
    `}
 
    ${media.greaterThan("huge")`
    font-size: 20px;
    line-height: 1.8;
    `}
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: ${({ theme }) => theme.font.header};
    color: ${({ theme }) => theme.color.base};
    font-weight: 300;
    margin: 0;
    line-height: 1;
}

h1 {
    ${media.lessThan("small")`
    font-size: 30px;
    line-height: 1.2;
    `}

    ${media.between("small", "medium")`
    font-size: 32px;
    line-height: 1.3;
    `}

    ${media.between("medium", "large")`
    font-size: 34px;
    line-height: 1.4;
    `}

    ${media.between("large", "huge")`
    font-size: 36px;
    line-height: 1.5;
    `}
 
    ${media.greaterThan("huge")`
    font-size: 38px;
    line-height: 1.6;
    `}
}

h2 {
    ${media.lessThan("small")`
    font-size: 27px;
    line-height: 1.2;
    `}

    ${media.between("small", "medium")`
    font-size: 28px;
    line-height: 1.3;
    `}

    ${media.between("medium", "large")`
    font-size: 29px;
    line-height: 1.4;
    `}

    ${media.between("large", "huge")`
    font-size: 31px;
    line-height: 1.5;
    `}
 
    ${media.greaterThan("huge")`
    font-size: 33px;
    line-height: 1.6;
    `}
}

h3 {
    ${media.lessThan("small")`
    font-size: 23px;
    line-height: 1.2;
    `}

    ${media.between("small", "medium")`
    font-size: 24px;
    line-height: 1.3;
    `}

    ${media.between("medium", "large")`
    font-size: 25px;
    line-height: 1.4;
    `}

    ${media.between("large", "huge")`
    font-size: 27px;
    line-height: 1.5;
    `}
 
    ${media.greaterThan("huge")`
    font-size: 29px;
    line-height: 1.6;
    `}
}

h4 {
    ${media.lessThan("small")`
    font-size: 21px;
    line-height: 1.2;
    `}

    ${media.between("small", "medium")`
    font-size: 22px;
    line-height: 1.3;
    `}

    ${media.between("medium", "large")`
    font-size: 23px;
    line-height: 1.4;
    `}

    ${media.between("large", "huge")`
    font-size: 24px;
    line-height: 1.5;
    `}
 
    ${media.greaterThan("huge")`
    font-size: 25px;
    line-height: 1.6;
    `}
}

h5 {
    ${media.lessThan("small")`
    font-size: 18px;
    line-height: 1.2;
    `}

    ${media.between("small", "medium")`
    font-size: 19px;
    line-height: 1.3;
    `}

    ${media.between("medium", "large")`
    font-size: 20px;
    line-height: 1.4;
    `}

    ${media.between("large", "huge")`
    font-size: 21px;
    line-height: 1.5;
    `}
 
    ${media.greaterThan("huge")`
    font-size: 22px;
    line-height: 1.6;
    `}
}

h6 {
    ${media.lessThan("small")`
    font-size: 16px;
    line-height: 1.2;
    `}

    ${media.between("small", "medium")`
    font-size: 17px;
    line-height: 1.3;
    `}

    ${media.between("medium", "large")`
    font-size: 18px;
    line-height: 1.4;
    `}

    ${media.between("large", "huge")`
    font-size: 19px;
    line-height: 1.5;
    `}
 
    ${media.greaterThan("huge")`
    font-size: 20px;
    line-height: 1.6;
    `}
}

ul {
    display: block;
    list-style-type: circle;
    list-style-position: outside;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0rem;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
    line-height: 1.5;
}

li {
    margin-left: 1rem;
    margin-top: 0;
}

a {
    text-decoration: none;
    background-color: transparent;

    &:hover {
        color: ${({ theme }) => theme.color.base};
        text-decoration: none;
    }
}

img {
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

button {
    display: inline-block;
    border: none;
    padding: 1rem 2rem;
    margin: 0;
    text-decoration: none;
    background: ${({ theme }) => theme.color.base};
    color: ${({ theme }) => theme.color.white};
    font-family: sans-serif;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out,
        transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;

    :hover {
        background-color: ${({ theme }) => theme.color.baseDark};
        border-style: none;
    }

    :focus {
        border: 1px solid ${({ theme }) => theme.color.base};
    }
    :active {
        //transform: scale(0.99);
        background-color: ${({ theme }) => theme.color.baseDark};
    }
}

`;