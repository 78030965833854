import styled from 'styled-components'
//import media from "styled-media-query"
import { generateMedia } from "styled-media-query";
import { Container, media } from "../../utils/utils"

export const Foot = styled.footer`
    text-align: center;
    background-color: ${({ theme }) => theme.color.baseLight};
`;

export const FooterHeader = styled.div`
    padding: 3vh;

    p {
        color: ${({ theme }) => theme.color.baseDark};
        white-space: pre-wrap;
    }
`;

export const FooterHeaderH = styled.h3`
    color: ${({ theme }) => theme.color.baseDark};
`;

export const FooterInfo = styled.div`
    background-color: ${({ theme }) => theme.color.base};
`;

export const FooterInfoWrapper = styled(Container)`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-top: 2rem;
    padding-bottom: 2rem;

    ${media.lessThan("small")`
    grid-template-columns: repeat(1, 1fr);
    `}

    ${media.between("small", "medium")`
    grid-template-columns: repeat(2, 1fr);
    `}
 
    ${media.greaterThan("medium")`
    grid-template-columns: repeat(4, 1fr);
    `}

    p, li, a {
        color: ${({ theme }) => theme.color.white};
    }
`;

export const FooterLogo = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

    div {
        width: 50% !important;
        height: auto !important;
    }
    ${media.lessThan("medium")`
    display: none;
    `}
`;

export const FooterAdres = styled.div`
    ${media.lessThan("small")`
    display: none;
    `}
    p {
        :first-child {
            font-weight: 700;
        }
    }

`;

export const FooterKontakt = styled.div`
    a {
        font-weight: 700;
        transition: all .4s ease-in-out;

        :hover {
            font-weight: 900;
            color: ${({ theme }) => theme.color.baseExtraDark};
        }
    }

    div {
        margin-top: 2rem;

        a {
            font-size: 30px;
            margin: 0 1rem;
        }
    }


`;

export const FooterLinki = styled.ul`
    list-style-type: none;
    display: block;

    ${media.lessThan("medium")`
    display: none;
    `}

    li {
        margin-bottom: 0rem;
        margin-top: 0rem;
        line-height: 1;
        padding-top: .4rem;
        padding-bottom: .4rem;
    }

    a {
        font-weight: 400;
        line-height: 1;
        white-space: pre-wrap;
        transition: all .4s ease-in-out;

        :hover {
            font-weight: 500;
            color: ${({ theme }) => theme.color.baseDark};
        }
    }

`;


export const FooterIks = styled.div`
    background-color: ${({ theme }) => theme.color.baseLight};
    padding: .5rem 0;

    a, p {
        color: ${({ theme }) => theme.color.baseDark};
        opacity: .8;
        font-weight: 300;
        line-height: 1;

        ${media.lessThan("small")`
        font-size: 14px;
        `}

        ${media.between("small", "medium")`
        font-size: 15px;
        `}

        ${media.between("medium", "large")`
        font-size: 16px;
        `}

        ${media.between("large", "huge")`
        font-size: 17px;
        `}
 
        ${media.greaterThan("huge")`
        font-size: 18px;
        `}
    }

    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: .6rem;
    }

    a {
        font-weight: 400;
        margin-left: .5rem;
        transition: all .4s ease-in-out;
        :hover {
            font-weight: 700;
        }
    }
`;