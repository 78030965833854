import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image'
import { media } from '../../../utils/utils'

const ItemWrapper = styled.div`
    position: fixed;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.color.white};

    ${media.greaterThan("medium")`
    display: none;
    `}

    > div {
        margin: 1rem;
        width: 15%;
    }

    a {
        color: ${({ theme }) => theme.color.font};
        //background-color: ${({ theme }) => theme.color.baseLight};
        text-align: left;
        font-size: 20px;
        padding: 1.5rem 1rem 1.5rem 1rem;
        transition: all .5s;
        border-style: none;

        :hover, 
        &.active {
            background-color: ${({ theme }) => theme.color.baseLight};
            color: ${({ theme }) => theme.color.baseDark};
        }
    }
`;

const NavMobile = (props) => {
    return (
        <ItemWrapper>
            <Img fluid={props.logo}
                alt="logo" />
            {props.children}
        </ItemWrapper>
    )
}
export default NavMobile