import React from 'react';
import styled from 'styled-components';
import { media } from '../../../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Icon = styled.button`
    position: fixed;
    right: 1rem;
    top: 1rem;
    display: block;

    height: 56px;
    width: 56px;

    font-size: 30px;
    color: ${({ theme }) => theme.color.base};

    background-color: rgb(255, 255, 255);
    border: 2px solid white;
    border-radius: 10px;

    padding: .5rem .8rem;
    opacity: .9;
    z-index: 8;

    cursor: pointer;

    :hover {
            background-color: ${({ theme }) => theme.color.baseLight};
            color: ${({ theme }) => theme.color.baseDark};
        }

    ${media.greaterThan("medium")`
    display: none;
    `}
`;

const HamburgerMenuIcon = (props) => {
    return (

        <Icon onClick={props.onClick}>
            <FontAwesomeIcon
                icon={props.state ? faTimes : faBars} />
        </Icon>

    )
}
export default HamburgerMenuIcon

/*
<HamburgerMenuIcon
                onClick={this.handleClick}
                state={this.state.clicked} />
*/