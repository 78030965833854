export const theme = {
    color: {
        font: 'rgba(40, 46, 46, .9)',
        white: 'rgba(255, 255, 255, 1)',
        base: '#BBD3D5',
        base2: '#9cbec0',
        baseLight: '#DCE9EA',
        baseExtraLight: '#F2F7F7',
        baseDark: '#56817f',
        baseExtraDark: '#00566b',
        baseSoftDarkOp: '#0b2d3d',
        baseOp: 'rgba(0,86,107,0.9)',

        sec: '#bfc4c3',
        secLight: '#ffe2a2',
        secExtraLight: '#fff4da',
        secDark: '#797264',
        secExtraDark: '#494642',

        opacityBlack06: 'rgba(0, 0, 0, .6)',
    },

    font: {
        main: 'Alegreya Sans',
        header: 'Merriweather',
        special: 'Yantramanav',

        weight: 300,
        weightB: 500,

        sizeSmall: '16px',
        size: '19px',

        size: {
            p: {
                xs: '16px',
                s: '17px',
                m: '18px',
                l: '19px',
                xl: '20px',
            },

            l: {
                xs: '14px',
                s: '15px',
                m: '16px',
                l: '17px',
                xl: '18px',
            },

            h1: {
                xs: '30px',
                s: '32px',
                m: '34px',
                l: '36px',
                xl: '38px',
            },

            h2: {
                xs: '27px',
                s: '28px',
                m: '29px',
                l: '31px',
                xl: '33px',
            },

            h3: {
                xs: '23px',
                s: '24px',
                m: '25px',
                l: '27px',
                xl: '29px',
            },

            h4: {
                xs: '21px',
                s: '22px',
                m: '23px',
                l: '24px',
                xl: '25px',
            },

            h5: {
                xs: '18px',
                s: '19px',
                m: '20px',
                l: '21px',
                xl: '22px',
            },

            h6: {
                xs: '16px',
                s: '17px',
                m: '18px',
                l: '19px',
                xl: '20px',
            },
        }
    },

    breakpoints: {
        xs: "0px",
        sm: "576px",
        md: "980px",
        lg: "1320px",
        xl: "1921px"
    },
}