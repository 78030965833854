import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { media } from '../../utils/utils'

import { list } from '../../utils/pageStructure'
import NavbarHeader from './navbarComponents/NavbarHeader/NavbarHeaderCentered';
import NavMobile from './navbarComponents/NavMobile';
import HamburgerMenuIcon from './navbarComponents/HamburgerMenuIcon';

const navbarItemCount = Object.keys(list).length;

const Navbar = styled.nav`
    position: sticky;
    top: 0;
    z-index: 2;

    height: 50px;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    border-color: ${props => props.theme.color.baseExtraLight};
    border-width: 1px 0px 1px 0px;
    border-style: solid;

    display: flex;
    justify-content: center;

    ${media.lessThan("medium")`
    display: none;
    `}
`;

const NavbarItemWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(${navbarItemCount}, minmax(100px, 1fr));
    //justify-content: center;
`;

const NavbarItem = styled(Link)`
    text-align: center;
    padding: 0 1.5rem;
    display: grid;
    align-items: center;
    width: 100%;
    white-space: pre-wrap;

    background-color: transparent;
    color: ${({ theme }) => theme.color.font};
    font-size: 17px;
    font-weight: 300;
    line-height: 1.1;
    border-style: solid;
    border-width: 0 0 3px 0;
    border-color: transparent;
    transition: all .3s;

    &.active,
    :hover {
        color: ${({ theme }) => theme.color.base2};
        border-color: ${({ theme }) => theme.color.base2};
    }

    &.active {
        font-weight: 400;
    }
`;

class Navbar_centered extends React.Component {

    state = { clicked: false }

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }

    render() {
        const menu = list.map(item => (
            <NavbarItem
                activeClassName="active"
                key={item.name}
                to={item.path}>{item.name}
            </NavbarItem>
        ))

        return (<>
            {/*WAŻNA INFORMACJA, ALBO NUMERY TELEFONU*/}
            <NavbarHeader
                logo={this.props.logo}
                tel={this.props.tel}
                email={this.props.email} />

            {/*BELKA NAWIGACJI*/}
            <Navbar id="menu"
                role="navigation"
                aria-label="main navigation">
                <NavbarItemWrapper>
                    {menu}
                </NavbarItemWrapper>
            </Navbar >

            {/*MENU MOBILNE*/}
            {this.state.clicked ? (
                <NavMobile logo={this.props.logo}>
                    {menu}
                </NavMobile>
            ) : (
                <></>
            )}

            {/*IKONKA HAMBURGER MENU POJAWIAJĄCE SIĘ TYLKO NA MOBILNYCH*/}
            <HamburgerMenuIcon
                onClick={this.handleClick}
                state={this.state.clicked} />
        </>
        )
    }
}
export default Navbar_centered

/*
<NavbarItemWrapper
                    className={this.state.clicked ? 'open' : 'close'}>

                    <Link to="/"
                        className="navbarC-mobile-logo"
                        onClick={this.handleClick}>

                        <Img fixed={this.props.logo}
                            objectFit="cover"
                            alt="logo" />
                    </Link>

                    {menu}

                </NavbarItemWrapper>
*/