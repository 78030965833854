import styled from 'styled-components';
//import media from "styled-media-query";
import BackgroundImage from 'gatsby-background-image'
import { generateMedia } from "styled-media-query";

export const media = generateMedia({
  huge: '1921px',
  large: '1320px',
  medium: '980px',
  small: '576px',
});

export const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  ${media.lessThan("small")`
  max-width: 540px;
  `}

  ${media.between("small", "medium")`
  max-width: 720px;
  `}

  ${media.between("medium", "large")`
  max-width: 960px;
  `}

  ${media.between("large", "huge")`
  max-width: 1200px;
  `}
 
  ${media.greaterThan("huge")`
  max-width: 1600px;
  `}
`;

export const CardWrapper = styled(Container)`
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;

  margin: 5rem auto 7rem auto;

  ${media.lessThan("small")`
  grid-template-columns: repeat(1, 1fr);
  margin: 0 auto 2rem auto;
  `}
 
  ${media.between("small", "medium")`
  grid-template-columns: repeat(2, 1fr);
  margin: 0 auto 2rem auto;
  `}

  ${media.between("medium", "large")`
  grid-template-columns: repeat(3, 1fr);
  `}

  ${media.between("large", "huge")`
  grid-template-columns: repeat(3, 1fr);
  `}
 
  ${media.greaterThan("huge")`
  grid-template-columns: repeat(3, 1fr);
  `}
`;

export const SectionImage = styled(BackgroundImage)`
  padding-top: 5rem;
  padding-bottom: 7rem;

  ${media.lessThan("small")`
  display: none;
  `}

  h1 {
    text-align: center;
    text-decoration: underline;
    text-decoration-thickness: .2rem;
  }

  h6 {
    text-align: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.secDark};
    font-family: ${({ theme }) => theme.font.main};
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 2rem;
    ${media.lessThan("small")`
    font-size: 12px;
    line-height: 1.2;
    `}

    ${media.between("small", "medium")`
    font-size: 14px;
    line-height: 1.2;
    `}

    ${media.between("medium", "large")`
    font-size: 16px;
    line-height: 1.2;
    `}

    ${media.between("large", "huge")`
    font-size: 18px;
    line-height: 1.2;
    `}
 
    ${media.greaterThan("huge")`
    font-size: 20px;
    line-height: 1.2;
    `}
  }

  p {
    text-align: center;
    white-space: pre-wrap;
  }
`;

export const SectionImageMobile = styled(BackgroundImage)`
  padding-top: 5rem;
  padding-bottom: 7rem;
  height: 100vh;
  display: flex;
  align-items: center;

  ${media.greaterThan("small")`
  display: none;
  `}

  h1 {
   text-align: center;
    text-decoration: underline;
    text-decoration-thickness: .2rem;
  }

  h6 {
   text-align: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.secDark};
    font-family: ${({ theme }) => theme.font.main};
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 2rem;
    font-size: 20px;
    line-height: 1.3;

    ${media.lessThan("small")`
    font-size: 12px;
    line-height: 1.2;
    `}
  }

  p {
    text-align: center;
    white-space: pre-wrap;
  }
`;

export const Section = styled.div`
  padding-top: ${props => props.pt || '5rem'};
  padding-bottom: ${props => props.pb || '5rem'};
  background-color: ${props => props.bgColor || 'transparent'};

  h3 {
    text-align: center;
  }
`;

export const Baner = styled(BackgroundImage)`
background-color: #659BAD;
height: auto;
text-align: left;

& > div {
    background: rgb(0,0,0);
    background: linear-gradient(90deg, 
        rgba(0,0,0,0) 0%, 
        rgba(0,136,206,0.7) 100%); 
    width: 100%;
    min-height: 40vh;
    display: flex;
    align-items: center;
    padding-top: 10rem;
    padding-bottom: 6rem;
}
  
h1, h2 {
  color: rgb(87, 87, 87);
  font-weight: 900;
}
`;