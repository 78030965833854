import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

import { list } from '../../utils/pageStructure'
import { Foot, FooterHeader, FooterHeaderH, FooterInfo, FooterInfoWrapper, FooterIks, FooterLinki, FooterKontakt, FooterAdres, FooterLogo } from './footerStyles'

const Footer = ({
    logo,
    iks,
    tel,
    email,
    ulica,
    nrBud,
    miasto,
    kod,
    woj,
    kraj,
    heading,
    subHeading }) => {

    const menu = list.map(item => (
        <li key={item.name}>
            <Link activeClassName="active"
                to={item.path}>{item.name}</Link>
        </li>
    ));

    return (
        <Foot>
            <FooterHeader>
                <FooterHeaderH>
                    {heading}
                </FooterHeaderH>
                <p>{subHeading}</p>
            </FooterHeader>

            <FooterInfo>
                <FooterInfoWrapper>

                    <FooterLogo>
                        <Img fluid={logo}
                            objectFit="cover"
                            alt="logo" />
                    </FooterLogo>

                    <FooterAdres>
                        <p>Adres</p>
                        <p>ul. {ulica} {nrBud}</p>
                        <p>{kod} {miasto}</p>
                        <p>Województwo {woj}</p>
                        <p>{kraj}</p>
                    </FooterAdres>

                    <FooterKontakt>
                        <Link to='/kontakt'>Kontakt</Link>
                        <p>+48 {tel}</p>
                        <p>{email}</p>

                        <div>
                            <a
                                target='blank'
                                href='https://www.facebook.com/MakijazPermanentnyMonikaGierczycka'>
                                <FontAwesomeIcon
                                    icon={faFacebook} />
                            </a>
                            <a
                                target='blank'
                                href='https://www.facebook.com/MakijazPermanentnyMonikaGierczycka'>
                                <FontAwesomeIcon
                                    icon={faInstagram} />
                            </a>
                        </div>

                    </FooterKontakt>

                    <FooterLinki>
                        {menu}
                    </FooterLinki>

                </FooterInfoWrapper>
            </FooterInfo>

            <FooterIks>
                <p>2021 © Wszystkie prawa zastrzeżone</p>
                <p>Projekt i realizacja:</p>
                <div>
                    <Img fixed={iks}
                        objectFit="cover"
                        alt="logo IKS design" />
                    <a href="https://iksdesign.pl/" target='blank'>
                        IKS Design
                    </a>
                </div>
            </FooterIks>
        </Foot>
    )
}
export default Footer