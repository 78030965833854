import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import media from "styled-media-query";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

import { Container } from '../../../../utils/utils';

const NavbarHeader = styled.div`
    padding-top: 1rem;
    padding-bottom: 1rem;

    ${media.lessThan("medium")`
        display: none;
    `}
`;

const NavbarInfo = styled(Container)`
    display: grid;
    grid-template-columns: 5fr 1fr 5fr;
`;

const NavbarLeft = styled.div`
    justify-self: left;
    margin-left: 3rem;

    p{
        text-align: left;
        font-weight: 300;
        color: ${({ theme }) => theme.color.secExtraDark};
        line-height: 1.1;
    }

    div {
        margin-top: .5rem;

        p {
            color: ${({ theme }) => theme.color.base2};
            font-weight: 500;
            line-height: 1.3;
        }
    }
`;

const NavbarRight = styled.div`
    justify-self: right;

    a {
        text-align: right;
        font-weight: 300;
        color: ${({ theme }) => theme.color.base2};
        font-size: 25px;
        margin-left: 1rem;
    }
`;

const Logo = styled(Link)`
    width: 100%;
    justify-self: center;
`;


const NavbarHeaderCentered = (props) => {
    return (
        <NavbarHeader>

            <NavbarInfo>
                <NavbarLeft>
                    <div>
                        <p>+48 {props.tel}</p>
                        <p>{props.email}</p>
                    </div>
                </NavbarLeft>

                <Logo to="/">
                    <Img fluid={props.logo}
                        alt="Logo Icon Beauty" />
                </Logo>

                <NavbarRight>
                    <a
                        target='blank'
                        href='https://www.facebook.com/MakijazPermanentnyMonikaGierczycka'>
                        <FontAwesomeIcon
                            icon={faFacebook} />
                    </a>
                    <a
                        target='blank'
                        href='https://www.instagram.com/icon_beauty_clinic/'>
                        <FontAwesomeIcon
                            icon={faInstagram} />
                    </a>
                </NavbarRight>
            </NavbarInfo>
        </NavbarHeader>

    )
}
export default NavbarHeaderCentered
